import { AxiosConfig } from "../../../ApiConfig";
import { useState, useEffect } from "react";
import { Row, Col, DatePicker, Input, notification } from "antd";
import * as yup from "yup";
import { useFormik } from "formik";
import ButtonReuse from "../../../components/Buttons/Button";
import Select from "react-select";
import { getToken } from "../../../auth";
import moment from "moment";
import { departmentRecord } from "../../../utils/enums";
require("./RecordData.scss");
import { useSelector } from "react-redux";

const DepartmentRecord = (props: {
  selectedRecord?: any;
  onCancelHandler?: any;
  departmentOptions?: any;
  currentDate: any;
}) => {
  const token = getToken();
  const { TextArea } = Input;
  const dateFormat = "YYYY/MM/DD";
  let talentData: any = localStorage.getItem("talentid");
  talentData = JSON.parse(talentData);
  const currentUserId = localStorage.getItem("userId");
  const user: any = localStorage.getItem("user");
  const users = JSON.parse(user);

  const validationSchema: any = yup.object({
    notes: yup.string().required("Please enter some notes"),
    date_of_change: yup
      .mixed()
      .required("Please enter the date of change")
      .nullable(false),
    new_value: yup.string().required("Please select the new department"),
  });

  const notify = (message: string, description?: string, color?: string) => {
    notification.open({
      message,
      description,
      style: { color },
    });
  };

  const [associatedBu, setAssociatedBu] = useState([]);
  const departmentData = useSelector(
    (state: any) => state.departments.departments
  );

  useEffect(() => {
    if (departmentData.length) {
      setAssociatedBu(departmentData);
    }
  }, [departmentData]);

  const filteredBu = associatedBu.filter(function (associatedBu: {
    flag: any;
  }) {
    return associatedBu.flag === true;
  });

  const associatedBuOptions = filteredBu.map(function (associatedBus: {
    name: any;
    label: any;
    id: any;
  }) {
    return {
      value: associatedBus.name,
      label: associatedBus.name,
      key: associatedBus.id,
    };
  });

  const formik: any = useFormik({
    initialValues: {
      old_value: "",
      new_value: "",
      new_dept_id: "",
      date_of_change: "",
      change_type_id: "",
      user_id: "",
      talent_id: "",
      notes: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      const selectedDate = moment(values.date_of_change);
      const currentDate = new Date();

      if (selectedDate.isBefore(currentDate, "day")) {
        notification.open({
          message: "",
          description: "Cannot make changes for the past dates.",
          style: { color: "red" },
        });
      } else {
        values = {
          ...values,
          old_value: talentData.Department,
          date_of_change: moment(values.date_of_change).format(dateFormat),
          change_type_id: props.selectedRecord["key"],
          user_id: currentUserId,
          talent_id: talentData["Talent_id"],
        };
        AxiosConfig.post("talents/changes/departments", values, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((response) => {
            talentData = { ...talentData, Department: values.new_value };
            localStorage.setItem("talentid", JSON.stringify(talentData));
            if (response.status === 200) {
              notify(response.data.status.message);
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          })
          .catch(function (error) {
            for (const key in values) {
              delete values[key];
            }
            notify("", error.response.data.status.message, "red");
          });
      }
    },
  });

  return (
    <div className="record-data-form">
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col span={24}>
            <label>{departmentRecord.labelForExistingDepartment}</label>
            <Input
              className="input--field"
              value={talentData.Department}
              disabled
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label>{departmentRecord.labelForNewDepartment}</label>
            {users.superAdmin ? (
              <Select
                options={associatedBuOptions}
                name="new_dept_id"
                placeholder="Select the Department"
                className="select--category"
                value={{
                  key: formik.values.new_dept_id,
                  label: formik.values.new_value,
                }}
                onChange={(value: any) => {
                  formik.setFieldValue("new_dept_id", value.key);
                  formik.setFieldValue("new_value", value.label);
                }}
              />
            ) : null}
            {users.superAdmin ? null : (
              <Select
                options={props.departmentOptions?.filter(
                  (option) => option.value !== talentData.Department
                )}
                name="new_dept_id"
                placeholder="Select the Department"
                className="select--field"
                value={{
                  key: formik.values.new_dept_id,
                  label: formik.values.new_value,
                }}
                onChange={(value: any) => {
                  formik.setFieldValue("new_dept_id", value.key);
                  formik.setFieldValue("new_value", value.label);
                }}
              />
            )}
            <p className="display_error">{formik.errors.new_value}</p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label style={{ display: "flex" }}>Date</label>
            <DatePicker
              defaultValue={undefined}
              format={dateFormat}
              value={formik.values.date_of_change}
              onChange={(val) => {
                formik.setFieldValue("date_of_change", val);
              }}
              placeholder="Select the Date"
              name="date_of_change"
              className="input--field"
            />
            <p className="display_error">{formik.errors.date_of_change}</p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label>{departmentRecord.labelForNotes}</label>
            <TextArea
              rows={4}
              placeholder="Enter the Notes"
              className="input--field textarea--field"
              value={formik.values.notes}
              name="notes"
              onChange={formik.handleChange}
            />
            <p className="display_error">{formik.errors.notes}</p>
          </Col>
        </Row>
        <div className="button-container">
          <ButtonReuse
            type="primary"
            className="primary-btn"
            htmlType="submit"
            value="Record"
          ></ButtonReuse>
          <ButtonReuse
            type="primary"
            className="primary-btn cancel--btn"
            value="Cancel"
            onClick={() => {
              formik.resetForm();
              props.onCancelHandler();
            }}
          ></ButtonReuse>
        </div>
      </form>
    </div>
  );
};

export default DepartmentRecord;
