import {
  Row,
  Col,
  Upload,
  Modal,
  Input,
  Image,
  notification,
  Button,
  Divider,
  Progress,
  Dropdown,
  Menu,
  Typography,
} from "antd";
import { useState, useEffect, useRef } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Formik } from "formik";
import * as yup from "yup";
import ButtonReuse from "../../../components/Buttons/Button";
import deleteIcon from "../../../assets/images/delete.png";
import editIcon from "../../../assets/images/edit.png";
import recognitionEmpty from "../../../assets/images/NoData.png";
import { PlusOutlined, DownOutlined } from "@ant-design/icons";
import ViewPoAttachment from "./ViewPoAttachments";
import "./RecordRevenue.scss";
import { saveAs } from "file-saver";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import EditRecordRevenue from "./EditRecordRevenue";
import Switch from "react-switch";
import {
  allowOnlyNumbers,
  convertCurrency,
  decimalValue,
} from "../../../utils/util";
import { useSelector } from "react-redux";
import { recordRevenue } from "../../../utils/enums";
import pdfPlaceholderImage from "../../../assets/images/pdfPlaceHolder.png";
import xlsxPlaceholderImage from "../../../assets/images/xlsx_thumbnail.png";
import docxPlaceholderImage from "../../../assets/images/docximage.png";
import Loader from "../../../components/Loader/Loader";
import { preventMinus } from "../../../utils/util";

const { TextArea } = Input;

const RecordRevenue = (props: {
  poId?: any;
  currentMonth?: any;
  currentMonthId?: any;
  activeYear?: any;
  close?: any;
  months?: any;
  monthListFiltered?: any;
  selectedCurrencyId?: any;
  currencyName?: any;
  currencySymbol?: any;
  archiveStauts?: any;
  Pearn?: any;
  currency_rate?: any;
  po_currency_conversion_rate?: any;
  directExpense?: number;
  adjustedAmounts?: number;
  rolesEditAccess: any;
  poDetails: any;
}) => {
  let fileData: any = useRef({});
  const { Text } = Typography;
  const { rolesEditAccess } = props;
  const [earnings, setEarnings] = useState([]);
  const [attachments_id, setAttachmentId] = useState();
  const [isEdit, setEdit] = useState(false);
  const [isDisabledInput, setIsDisabledInput] = useState(true);
  const [activeMonth, setActiveMonth] = useState([]);
  const [isModalVisibleImage, setIsModalVisibleImage] = useState(false);
  const [isModalVisibleDelete, setisModalVisibleDelete] = useState(false);
  const [addRecognition, setAddRecognition] = useState(false);
  const [isMonthSelected, setMonthSelected] = useState(false);
  const [monthId, setMonthId] = useState();
  const [openRecordRevenue, setOpenRecordRevenue] = useState(false);
  const maxClaimable = useRef("0");
  const [deleteEarnings, setDeleteEarnings] = useState();
  const [month, setMonth] = useState<string>("Select Month");
  const monthsArray: any = useRef([]);
  const mastercurrencies = useRef([]);
  const [selectedCurrencyRate, setSelectedCurrencyRate] = useState();
  const [Pearn, setPearn] = useState(false);
  const [droppedValue, setDroppedValue] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [maxExpenses, setMaxExpenses] = useState(0);

  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  var token = getToken();
  const ValueAmt = useRef();

  const isEditableString = localStorage.getItem("isEditable");
  const isEditable = isEditableString === "true";

  const currencyRate = useSelector(
    (store: any) => store?.currencyRate?.data?.currencyRate
  );

  const showViewModalImage = (po_earn_attachments: any) => {
    setAttachmentId(
      po_earn_attachments.map((row: any) => {
        return row.attachment_id;
      })
    );
    setIsModalVisibleImage(true);
  };
  const handleCancelModalImage = () => {
    setIsModalVisibleImage(false);
  };
  const showModalDelete = () => {
    setisModalVisibleDelete(true);
  };
  const handleCancelDeleteModal = () => {
    setisModalVisibleDelete(false);
    setAddRecognition(false);
  };

  const handleMonthSelected = (month_id) => {
    AxiosConfig.get("rates/" + month_id, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((data) => {
        setMonthSelected(true);
        mastercurrencies.current = data.data?.monthRates?.mastercurrencies;
        const temp: any = data.data?.monthRates?.mastercurrencies.filter(
          (currency: any) =>
            currency.currencyrates.currency_id === props.selectedCurrencyId
        );
        setSelectedCurrencyRate(
          temp[0]?.currencyrates.rate ?? props.currency_rate
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSwitchChange = () => {
    setPearn(!Pearn);
  };
  var FormData = require("form-data");
  var data = new FormData();
  const validationSchema = yup.object({
    earned_percentage: yup.number().required("Please add Earned Percentage"),
    notes: yup.string().trim().required("Please add Notes"),
  });
  useEffect(() => {
    getEarnings();
    setOpenRecordRevenue(false);
  }, [props.close, openRecordRevenue]);
  let currentArray: any = [];
  let monthArray: any = [];
  const getEarnings = () => {
    AxiosConfig.get("poearnings/" + props.poId, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const data = res.data.data.po_earn;
        data.sort(function (a: any, b: any) {
          return a.financialmonth_id - b.financialmonth_id;
        });
        setEarnings(data);
        calculateMaxExpense(data);
        currentArray = res.data.data.po_earn.filter((row: any) => {
          return row?.financialmonth.status === "1";
        });
        monthArray = res.data.data.po_earn.filter((row: any) => {
          return row?.financialmonth.status === "-1";
        });
        monthArray = [...currentArray, ...monthArray];
        const attachments = data.map((row) => row.po_earn_attachments).flat();
        attachments.forEach((row) => {
          getAttachments(row.attachment_id);
        });
        setActiveMonth(currentArray);
        setIsLoading(false);
      })
      .catch(() => {
        setEarnings([]);
        calculateMaxExpense(data);
        setIsLoading(false);
      });
  };

  const [deletedMonthIds, setDeletedMonthIds] = useState<number[]>([]); // Track deleted months

  const deleteData = () => {
    AxiosConfig.delete("/poearnings/" + deleteEarnings, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.status.status === 200) {
          const notify = () => {
            notification.open({ message: res.data.status.message });
          };

          notify();
  
          const deletedEntry: any = earnings.find((entry: any) => entry.id === deleteEarnings);
  
          if (deletedEntry && deletedEntry.financialmonth) {
            const financialMonthId = deletedEntry.financialmonth.id;
  
           
            monthsArray.current = monthsArray.current.filter(
              (monthId) => monthId !== financialMonthId
            );
  
            
            setDeletedMonthIds((prevDeletedIds) => [...prevDeletedIds, financialMonthId]);
  
            
  
          handleCancelDeleteModal();
          getEarnings();
          }
        }
      })
      .catch(function () {
        const notify = () => {
          notification.open({
            message: "",
            description: "Error in the delete Recognition",
            style: { color: "red" },
          });
        };
        notify();
      });
  };
  
  useEffect(() => {
    const uniqueMonths = new Set(
      earnings
        .map((row: any) => row?.financialmonth?.id)
        .filter((monthId) => !deletedMonthIds.includes(monthId)) 
    );
  
    
    monthsArray.current = Array.from(uniqueMonths);
  
  }, [earnings, deletedMonthIds]);
  
  let perc = 0;
  earnings.forEach((row: { earned_percentage: any }) => {
    perc = perc + row?.earned_percentage;
  });
  maxClaimable.current = 100 - perc + "%";
  const currentEarn = activeMonth.map((row: { earned_percentage: any }) => {
    return row?.earned_percentage;
  });
  const currentNotes = activeMonth.map((row: { notes: any }) => {
    return row?.notes;
  });

  const [image, setImage] = useState<any>([]);
  let image_arr: any = [];

  const getAttachments = (attachment_id: any) => {
    AxiosConfig.get("poearnings/attachments/" + attachment_id, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    }).then((res) => {
      let image_obj: any = {};
      const url: any = URL.createObjectURL(res.data);
      fileData["current"][url] = { data: res.data, attachment_id };
      image_obj["attachment_id"] = attachment_id;
      image_obj["url"] = url;
      image_arr.push(image_obj);

      setImage(image_arr);
    });
  };

  var newCurrentEarn = currentEarn.join();
  let newCurrentNotes = currentNotes.join();

  const handleCancel = () => {
    setIsDisabledInput(true);
    setAddRecognition(false);
    setMonthSelected(false);
    refreshPage();
  };
  const refreshPage = () => {
    props.close;
  };
  
  const handleAddRecognition = () => {
    if (isEditable) {
      setFiles([]);

      setAddRecognition(true);
      setMonth("Select Month");
    } else {
      notification.open({
        message: "",
        description:
          "To edit/add values, please switch to base currency editable mode",
        style: { color: "red" },
      });
    }
  };

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const [previewTitle, setPreviewTitle] = useState();
  const [files, setFiles] = useState<any>([]);

  const handleCancelModal = () => {
    setPreviewVisible(false);
  };

  const handlePreview = async (file: any) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleAttachmentChange = ({ fileList }: any) => {
    setFiles(fileList);
  };
  function calculateTotalExpense(data) {
    let totalExpense = 0;

    data.forEach((record) => {
      const status = record.financialmonth.status;
      if (status == 1 || status == 0) {
        // Current or future month
        totalExpense += record.projected_expenses;
      } else if (status == -1) {
        // Past month
        totalExpense += record.expense;
      }
    });

    return totalExpense;
  }

  function calculateMaxExpense(data) {
    let totExp: any = calculateTotalExpense(data);
    setMaxExpenses(props.poDetails.projected_expenses - totExp);
  }

  const monthMenu = (
    <Menu title="Select Month">
      {props.monthListFiltered.map(
        (month: any) =>
          !monthsArray.current.includes(month.id) && (
            <Menu.Item
              key={month.id}
              onClick={() => {
                handleMonthSelected(month.id);
                setMonthId(month.id);
                setMonth(month.month + " " + month.year);
              }}
            >
              {month.month + " " + month.year}
            </Menu.Item>
          )
      )}
    </Menu>
  );
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{recordRevenue.labelForUpload}</div>
    </div>
  );
  const handleDownload = (file_id: any) => {
    if (
      fileData &&
      fileData["current"] &&
      fileData["current"][file_id] &&
      fileData["current"][file_id]["data"] &&
      fileData["current"][file_id]["attachment_id"]
    ) {
      saveAs(
        fileData["current"][file_id]["data"],
        fileData["current"][file_id]["attachment_id"].split("^").pop()
      );
    } else {
      console.error(
        "File data or 'data' property or 'attachment_id' is undefined."
      );
    }
  };

  const getImages = () => {
    const images = image
      .filter((img) =>
        (attachments_id || []).some(
          (attachmentId: string) => attachmentId === img["attachment_id"]
        )
      )
      .map((img_url: any, index: any) => {
        const fileNameWithExtension =
          fileData["current"][img_url.url]?.["attachment_id"];

        const fileName = fileNameWithExtension
          ? fileNameWithExtension.split("_").slice(0, -1).join("_")
          : "Unknown";

        const fileType = fileNameWithExtension
          ? fileNameWithExtension.split(".").pop()
          : "Unknown";
        {
          return (
            <div className="attachment" key={index}>
              {fileType.toLowerCase() === "pdf" ? (
                <div className="pdf-placeholder">
                  <img
                    src={pdfPlaceholderImage}
                    alt="PDF Placeholder"
                    className="pdf-placeholder-image"
                  />
                </div>
              ) : fileType.toLowerCase() === "xlsx" ? (
                <div className="xlsx-placeholder">
                  <img
                    src={xlsxPlaceholderImage}
                    alt="XLSX Placeholder"
                    className="xlsx-placeholder-image"
                  />
                </div>
              ) : fileType.toLowerCase() === "docx" ? (
                <div className="docx-placeholder">
                  <img
                    src={docxPlaceholderImage}
                    alt="DOCX Placeholder"
                    className="docx-placeholder-image"
                  />
                </div>
              ) : (
                <Image
                  style={{
                    border: "1px solid #989898",
                    borderRadius: "4px",
                  }}
                  width={62}
                  height={62}
                  src={img_url.url}
                  alt="Attachment"
                />
              )}
              <Button
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
                size="small"
                onClick={() => handleDownload(img_url.url)}
              />
              <text>{fileName}</text>
            </div>
          );
        }
      });
    return images;
  };

  const RecognitionForm = (
    <Formik
      initialValues={{
        financialmonth_id: props.currentMonthId,
        earned_percentage: "",
        po_id: props.poId,
        notes: "",
        p_earn: Pearn,
        po_currency_conversion_rate: "",
        files: [],
        projected_expenses: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        if (
          !values?.earned_percentage &&
          values?.earned_percentage?.length == 0
        ) {
          const notify = () => {
            notification.open({
              message: "",
              description: "Please enter Earned Percentage Value",
              style: { color: "red" },
            });
          };
          {
            notify();
          }
        } else {
          const totalAmount = earnings.reduce(
            (total, item: any) =>
              total + (parseFloat(item?.projected_expenses) || 0),
            0
          );
          const directExpenseValue = parseFloat(values?.projected_expenses);
          const propsDirectExpense = parseFloat(
            props?.directExpense?.toString() ?? "0"
          );
          if (totalAmount + directExpenseValue > propsDirectExpense) {
            await new Promise((resolve, reject) => {
              Modal.confirm({
                title:
                  "You are exceeding the max projected expenses - do you still want to proceed?",
                okText: "Yes",
                cancelText: "No",
                onOk() {
                  resolve(undefined); // Resolves the Promise when "Yes" is clicked
                },
                onCancel() {
                  reject(undefined); // Rejects the Promise when "No" is clicked
                },
              });
            });
          }
          for (let i = 0; i < files.length; i++) {
            data.append("files", files[i].originFileObj);
          }
          data.append("financialmonth_id", monthId);
          data.append("po_id", values.po_id);
          data.append("earned_percentage", values.earned_percentage);
          data.append("notes", values.notes);
          data.append("p_earn", Pearn);
          data.append("po_currency_conversion_rate", selectedCurrencyRate);
          data.append(
            "projected_expenses",
            values?.projected_expenses ? values?.projected_expenses : 0
          );

          AxiosConfig.post("poearnings", data, {
            headers: { Authorization: `Bearer ${token}` },
          })
            .then((response) => {
              if (response.status == 200) {
                const notify = () => {
                  notification.open({
                    message: response.data.status.message,
                  });
                };
                {
                  notify();
                }
                setOpenRecordRevenue(true);
                getEarnings();
                handleCancel();
              }
            })
            .catch(function (error) {
              const notify = () => {
                notification.open({
                  message: "",
                  description: error.response.data.status.message,
                  style: { color: "red" },
                });
              };
              {
                notify();
                for (const key in values) {
                  data.delete(key);
                }
              }
            });
        }
      }}
    >
      {({ handleSubmit, handleChange, errors, values, resetForm }) => (
        <form onSubmit={handleSubmit} className="add_po_earning_form">
          <>
            <div className="listing-bg main-div">
              {addRecognition && (
                <Row>
                  <Col span={6}>
                    <Divider
                      type="horizontal"
                      orientation="right"
                      className="divider"
                    >
                      <Dropdown overlay={monthMenu}>
                        <Button>
                          {month || "Select Month"} <DownOutlined />
                        </Button>
                      </Dropdown>
                    </Divider>
                  </Col>
                  <Col span={18}>
                    <Divider orientation="right"></Divider>
                  </Col>
                </Row>
              )}
              {isMonthSelected && (
                <>
                  <Row>
                    <Col span={12}>
                      <label>{recordRevenue.labelForValue}</label>
                      <Input
                        placeholder="Enter the Value"
                        name="earned_percentage"
                        onChange={(event: any) => {
                          const val = allowOnlyNumbers(event);
                          if (val !== false) {
                            return handleChange(event);
                          }
                          return false;
                        }}
                        value={values.earned_percentage}
                        className="input--field"
                      ></Input>

                      <p className="display_error">
                        {errors.earned_percentage}
                      </p>
                      <Row>
                        <Switch
                          onChange={handleSwitchChange}
                          checked={Pearn}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          width={36}
                          height={20}
                          handleDiameter={16}
                          onColor="#EF0723"
                          offColor="#3FF528"
                        />
                        <Col className="earnswitch">
                          {Pearn ? <p>{"LOSS"}</p> : <p> {"GAIN"}</p>}
                        </Col>
                      </Row>
                    </Col>{" "}
                    <Col span={12}>
                      <label>{recordRevenue.labelForNotes} </label>
                      <br />
                      <TextArea
                        rows={3}
                        className="textarea--field"
                        placeholder="Enter the Notes"
                        name="notes"
                        onChange={handleChange}
                        value={values.notes}
                      />
                      <p className="display_error">{errors.notes}</p>
                    </Col>
                    <Col>
                      <label>
                        {recordRevenue.textForProjectedDirectExpenses}
                      </label>
                      <Input
                        type="number"
                        placeholder="Enter the expenses"
                        name="projected_expenses"
                        onChange={(event: any) => {
                          ValueAmt.current = event.target.value;
                          return handleChange(event);
                        }}
                        value={values.projected_expenses}
                        addonBefore={<span className="select-before">INR</span>}
                        className="direct-expense-input-revenue"
                        min={0}
                        onKeyPress={preventMinus}
                        step="any"
                      ></Input>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={12}>
                      <label>{recordRevenue.labelForAttachments}</label>
                      <Upload
                        listType="picture-card"
                        fileList={files}
                        onPreview={handlePreview}
                        onChange={handleAttachmentChange}
                        beforeUpload={() => false}
                      >
                        {uploadButton}
                      </Upload>
                      <Modal
                        visible={previewVisible}
                        title={previewTitle}
                        footer={null}
                        onCancel={handleCancelModal}
                      >
                        <img
                          alt="example"
                          style={{ width: "100%" }}
                          src={previewImage}
                        />
                      </Modal>
                    </Col>
                  </Row>

                  <div
                    className="button-container"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginRight: "0",
                    }}
                  >
                    {props.rolesEditAccess && (
                      <ButtonReuse
                        type="primary"
                        className="primary-btn"
                        htmlType="submit"
                        value="Add"
                      ></ButtonReuse>
                    )}
                    {props.rolesEditAccess && (
                      <ButtonReuse
                        type="primary"
                        className="cancel-btn"
                        htmlType="submit"
                        value="Cancel"
                        onClick={() => {
                          setEdit(false);
                          handleCancel();
                          resetForm();
                        }}
                      ></ButtonReuse>
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        </form>
      )}
    </Formik>
  );

  localStorage.setItem("isEarning", JSON.stringify(activeMonth.length));
  return (
    <>
      <Row className="total-claimed">
        <Col span={10}>
          <Row>
            <Col span={12}>
              <p className="totalClaimed">
                {recordRevenue.labelForTotalClaimed}-{perc?.toFixed(2)}%
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Progress
                className="RevenueProgress"
                percent={perc}
                showInfo={false}
              />
            </Col>
          </Row>
        </Col>

        <Col span={6} className="financialMonth">
          <Row>
            <label> {recordRevenue.labelForExpenseLimit} </label>
          </Row>
          <Row>
            <strong
              style={{
                color: maxExpenses < 0 ? "red" : "green",
              }}
            >
              {convertCurrency(maxExpenses, currencyRate)}
            </strong>
          </Row>
        </Col>

        <Col span={6} className="financialMonth">
          <Row>
            <Col>
              <label>
                {recordRevenue.labelForCurrentFinancialMonth}
                <strong>
                  {props.currentMonth} {props.activeYear}
                </strong>
              </label>
            </Col>
          </Row>
          {/* <Row>
            <Col span={8}></Col>
          </Row> */}
        </Col>
      </Row>
      <Row>
        {!isEdit && !props.archiveStauts && props.rolesEditAccess && (
          <ButtonReuse
            type="primary"
            className="primary-btn add-recognition"
            onClick={handleAddRecognition}
            value="Add Recognition"
          ></ButtonReuse>
        )}
      </Row>
      {earnings.length == 0 && !addRecognition && (
        <img
          src={recognitionEmpty}
          width="184"
          height="152"
          className="recognitionEmpty-img"
        />
      )}
      {addRecognition && RecognitionForm}
      {!isLoading ? (
        <div>
          {isDisabledInput && (
            <>
              {earnings &&
                earnings.map(
                  (
                    row: {
                      i: any;
                      id: any;
                      financialmonth: any;
                      earned_percentage: any;
                      notes: any;
                      stus_executed: any;
                      earned_value_in_base: any;
                      earned_value: any;
                      dropped_value_in_base: any;
                      dropped_value: any;
                      expense: number;
                      projected_expenses: number;
                      po_earn_attachments: any;
                    },
                    index: any
                  ) => {
                    monthsArray.current = [
                      ...monthsArray.current,
                      row?.financialmonth.id,
                    ];
                    let dropped_value_in_base = row?.dropped_value_in_base
                      ? row?.dropped_value_in_base
                      : 0;
                    let earned_value_in_base = row?.earned_value_in_base
                      ? row?.earned_value_in_base
                      : 0;
                    return (
                      <div key={index} className="listing-bg">
                        <Row>
                          <Col span={!addRecognition && !isEdit ? 20 : 24}>
                            <Divider
                              type="horizontal"
                              orientation="left"
                              className="divider"
                            >
                              {row?.financialmonth.month}-
                              {row?.financialmonth.year}
                            </Divider>
                          </Col>
                          {!addRecognition &&
                            !isEdit &&
                            !props.archiveStauts &&
                            isEditable && (
                              <Col span={2}>
                                <Divider type="horizontal" orientation="right">
                                  {rolesEditAccess && (
                                    <Col>
                                      {row?.financialmonth.status >= 0 && (
                                        <img
                                          src={editIcon}
                                          width="25"
                                          height="25"
                                          onClick={() => {
                                            setEdit(true);
                                            setIsDisabledInput(false);
                                            setMonthId(row?.financialmonth.id);
                                            setDroppedValue(
                                              row?.dropped_value_in_base
                                                ? true
                                                : false
                                            );
                                          }}
                                        />
                                      )}
                                    </Col>
                                  )}
                                </Divider>
                              </Col>
                            )}
                          {!addRecognition &&
                            !isEdit &&
                            !props.archiveStauts &&
                            isEditable && (
                              <Col span={2}>
                                <Divider type="horizontal" orientation="right">
                                  {rolesEditAccess && (
                                    <Col>
                                      {row?.financialmonth.status >= 0 && (
                                        <img
                                          src={deleteIcon}
                                          width="25"
                                          height="25"
                                          onClick={() => {
                                            setDeleteEarnings(row?.id);
                                            showModalDelete();
                                            setMonthId(row?.financialmonth.id);
                                          }}
                                        />
                                      )}
                                    </Col>
                                  )}
                                  <Modal
                                    title="Delete Current Month Revenue"
                                    visible={isModalVisibleDelete}
                                    onCancel={handleCancelDeleteModal}
                                    cancelText="Cancel"
                                    footer={false}
                                    className="deleteModalMain"
                                  >
                                    <p>
                                      {
                                        recordRevenue.textForDeletingCurrentMonth
                                      }
                                    </p>
                                    <Row>
                                      <Col span={12}>
                                        <ButtonReuse
                                          value="Delete"
                                          type="submit"
                                          className="modal-deleteBtn"
                                          onClick={() => {
                                            deleteData();
                                          }}
                                        />
                                      </Col>
                                      <Col span={12}>
                                        <ButtonReuse
                                          value="Cancel"
                                          type="submit"
                                          className="modal-deleteCancelBtn"
                                          onClick={handleCancelDeleteModal}
                                        />
                                      </Col>
                                    </Row>
                                  </Modal>
                                </Divider>
                              </Col>
                            )}
                        </Row>
                        <Row>
                          <Col span={8}>
                            <label>{recordRevenue.labelForValue}</label>
                            <p
                              className="newCurrentEarn"
                              style={{ display: "grid" }}
                            >
                              {row?.earned_percentage}

                              {/* decimalValueis stored in utils */}
                              {isEditable ? (
                                <>
                                  <Text>
                                    {recordRevenue.textForActualValue}
                                    &nbsp;&nbsp;
                                    {
                                      props.currencySymbol[
                                        props.selectedCurrencyId
                                      ]
                                    }
                                    &nbsp;
                                    {row?.earned_value !== null
                                      ? row?.earned_value.toLocaleString(
                                          "en-IN",
                                          {
                                            minimumFractionDigits: decimalValue,
                                            maximumFractionDigits: decimalValue,
                                          }
                                        )
                                      : row?.dropped_value?.toLocaleString(
                                          "en-IN",
                                          {
                                            minimumFractionDigits: decimalValue,
                                            maximumFractionDigits: decimalValue,
                                          }
                                        )}
                                  </Text>
                                  {props.currencySymbol[
                                    props.selectedCurrencyId
                                  ] !== "₹" && (
                                    <Text type="secondary">
                                      {earned_value_in_base !== null
                                        ? convertCurrency(
                                            earned_value_in_base,
                                            currencyRate
                                          )
                                        : convertCurrency(
                                            dropped_value_in_base,
                                            currencyRate
                                          )}
                                    </Text>
                                  )}
                                </>
                              ) : (
                                props.currencySymbol[
                                  props.selectedCurrencyId
                                ] !== "₹" && (
                                  <>
                                    <Text>
                                      {recordRevenue.textForActualValue}
                                    </Text>
                                    <Text type="secondary">
                                      {earned_value_in_base !== null
                                        ? convertCurrency(
                                            earned_value_in_base,
                                            currencyRate
                                          )
                                        : convertCurrency(
                                            dropped_value_in_base,
                                            currencyRate
                                          )}
                                    </Text>
                                  </>
                                )
                              )}
                            </p>
                          </Col>
                          <Col span={8}>
                            <label>{recordRevenue.textForEarnedStu} </label>
                            <p
                              className="newCurrentEarn"
                              style={{ display: "grid" }}
                            >
                              {row?.stus_executed.toFixed(2)}
                            </p>
                          </Col>
                          <Col span={8}>
                            <label>{recordRevenue.labelForNotes} </label>
                            <p className="newCurrentNotes">{row?.notes}</p>
                          </Col>
                          <Col span={8}>
                            <label>{recordRevenue.textForDirectExpenses}</label>
                            <p className="newCurrentNotes">
                              <span className="select-before">
                                {convertCurrency(row?.expense, currencyRate)}
                              </span>
                              {/* {row?.expense?.toLocaleString("en-IN")} */}
                            </p>
                          </Col>
                          <Col span={8}>
                            <label>
                              {recordRevenue.textForProjectedDirectExpenses}{" "}
                            </label>
                            <p className="newCurrentNotes">
                              <span className="select-before">
                                {row?.projected_expenses !== null &&
                                row?.projected_expenses !== undefined
                                  ? convertCurrency(
                                      row?.projected_expenses,
                                      currencyRate
                                    )
                                  : 0}
                              </span>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={4}>
                            <label>{recordRevenue.labelForAttachments}</label>
                            <ButtonReuse
                              value="View"
                              type="submit"
                              className="edit-image-button"
                              onClick={() =>
                                showViewModalImage(row.po_earn_attachments)
                              }
                            />
                          </Col>
                          <Col span={15}></Col>
                          <Col>
                            {row?.dropped_value_in_base != null &&
                            row?.dropped_value_in_base != 0 ? (
                              <p className="lostUsd-text ">
                                LOST {currencySymb?.currency_code}:
                                {currencySymb?.currency_symbol}
                                {row?.dropped_value_in_base}
                              </p>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                )}
            </>
          )}
        </div>
      ) : (
        <Loader />
      )}
      {!isDisabledInput && (
        <div className="listing-bg main-div">
          <EditRecordRevenue
            newCurrentEarn={newCurrentEarn}
            newCurrentNotes={newCurrentNotes}
            monthId={monthId}
            disabledInput={!isDisabledInput}
            currentEarningId={monthId}
            deleteData={deleteData}
            handleCancel={handleCancel}
            earnings={earnings}
            setEdit={setEdit}
            setDeleteEarnings={setDeleteEarnings}
            setOpenRecordRevenue={setOpenRecordRevenue}
            pEarn={droppedValue}
            currencyId={props.selectedCurrencyId}
            currency_rate={props.currency_rate}
            getEarnings={getEarnings}
            directExpense={props.directExpense}
          />
        </div>
      )}
      {isModalVisibleImage && (
        <Modal
          title="Attachments"
          className="AttachmentsModal"
          visible={isModalVisibleImage}
          onCancel={handleCancelModalImage}
        >
          <ViewPoAttachment images={getImages()} />
        </Modal>
      )}
    </>
  );
};
export default RecordRevenue;
