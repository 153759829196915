import { notification, Row, Col, Button, Typography, Progress } from "antd";
import AddInvoice from "./AddInvoice";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useState, useEffect, useRef } from "react";
import {
  FileExclamationOutlined,
  EditOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import moment from "moment";
import EditInvoice from "./EditInvoice";
import MarkAsSend from "./MarkAsSend";
import RenderMarkAsSent from "./RenderMarkAsSent";
import AddModal from "./Modal";
import {
  checkNull,
  convertNumber,
  convertCurrency,
  checkBase,
} from "../../../utils/util";
import { useDispatch, useSelector } from "react-redux";
import { getAllInvoice } from "../../../stores/actions/getAllInvoiceAction";
import Loader from "../../../components/Loader/Loader";
import { invoiced } from "../../../utils/enums";
require("./PurchaseOrderTable.scss");

const Invoice = (props: {
  newMonthId?: any;
  POid?: any;
  poDetails?: any;
  mastercurrencies?: any;
  currencyName?: any;
  currencySymbol?: any;
  archiveStatus?: any;
  activeKey?: string;
  addEditAccess: any;
}) => {
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const token = getToken();
  const dateFormat = "YYYY/MM/DD";
  const { Text } = Typography;
  const [invoice, setInvoice] = useState([]);
  const [loading, isLoading] = useState(true);
  const { addEditAccess } = props;
  // const [sentInvoiceData, setSentInvoiceData] = useState([]);
  // const [invoiceCheck, setInvoiceCheck] = useState([]);
  const currencyRate = useSelector(
    (store: any) => store?.currencyRate?.data?.currencyRate
  );
  const [invoiceVisibility, setInvoiceVisibility] = useState([]);
  const [value, setData] = useState<any>([]);
  const currentInvoiceAmt: any = useRef(0);
  const [markAsSendVsibleHandler, setmarkAsSendVisibleHandler] =
    useState(false);
  const [show, setShow] = useState(false);
  const [rowId, setRowId] = useState();
  const selectedInvoice = useRef({});
  const [lastIn, setLastIn] = useState(false);
  const [plannedInvoice, setPlannedInvoice] = useState("0");
  const [sentInvoice, setSentInvoice] = useState("0");
  const [netSentAmount, setNetSentAmount] = useState("0");
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  const dispatch: any = useDispatch();
  const isEditableString = localStorage.getItem("isEditable");
  const isEditable = isEditableString === "true";
  useEffect(() => {
    dispatch(getAllInvoice(props.POid));
  }, [dispatch]);

  const getData = () => {
    AxiosConfig.get(`invoice/${props.POid}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        let Data: any = Array(res.data.data);
        const tempArr: any = Array(res.data.data.invoiceList.length).fill(true);
        setData(Data);
        setInvoice(res.data.data.invoiceList);
        const count = res.data.data.invoiceList.filter(
          (obj: any) => obj.status == true
        ).length;
        if (count == 1) {
          setLastIn(true);
        }
        currentInvoiceAmt.current = res.data.data.totalInvoiceAmt;

        setInvoiceVisibility(tempArr ?? []);

        const plannedInvoiceValue =
          currencySymb?.currency_code !== "INR"
            ? convertNumber(res.data.data.plannedInvoice, 2)
            : checkBase(
                currencySymb?.currency_code,
                res.data.data.plannedInvoice
              );
        setPlannedInvoice(plannedInvoiceValue ?? 0);

        const sentInvoiceValue =
          currencySymb?.currency_code !== "INR"
            ? convertNumber(res?.data?.data?.sentInvoice, 2)
            : checkBase(
                currencySymb?.currency_code,
                res?.data?.data?.sentInvoice
              );
        setSentInvoice(sentInvoiceValue ?? 0);

        const netSentAmountValue =
          currencySymb?.currency_code !== "INR"
            ? convertNumber(res?.data?.data?.totalAdjustedAmt, 2)
            : checkBase(
                currencySymb?.currency_code,
                res?.data?.data?.totalAdjustedAmt
              );
        setNetSentAmount(netSentAmountValue ?? 0);
      })
      .finally(() => isLoading(false));
  };
  useEffect(() => {
    if (props.activeKey == "3") getData();
  }, [props.activeKey]);

  interface Currency {
    symbol: string;
    rate: number;
  }
  const [currencySymbols, setCurrencySymbols] = useState<Currency[]>([]);

  const getCurrencyList = async () => {
    if (token) {
      await AxiosConfig.get(`/rates/country/currencyList`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          const currenciesList = res.data.currenciesList || [];
          const currencies = currenciesList.map((currency) => ({
            symbol: currency.currency_symbol,
            rate: currency.rate,
          }));
          setCurrencySymbols(currencies);
        })
        .catch((error) => {
          console.error("Error fetching currency list:", error);
        });
    }
  };

  useEffect(() => {
    getCurrencyList();
  }, []);
  const convertToINR = (amount: number | string, rate: number): string => {
    const amountStr = amount.toString();

    const cleanedAmount = parseFloat(amountStr.replace(/,/g, ""));
    if (isNaN(cleanedAmount)) {
      console.error("Invalid amount provided");
      return "NaN";
    }

    const inrAmount = cleanedAmount * rate;
    return inrAmount.toFixed(2);
  };
  function checkAndConvertCurrency(value, currSymb) {
    const localStorageCurrencySymb = localStorage.getItem("cur_symbol");

    if (currSymb === localStorageCurrencySymb) {
      return `${currSymb} ${value}`;
    } else {
      const currency = currencySymbols.find((c) => c.symbol === currSymb);
      if (currency) {
        const convertedValue = convertToINR(value, currency.rate);

        const newconvertedValue = Number(convertedValue);

        return convertCurrency(newconvertedValue, currencyRate);
      }
    }
  }

  const visibleHandler = () => {
    if (isEditable) {
      setVisible(!visible);
    } else {
      notification.open({
        message: "",
        description:
          "To edit/add values, please switch to base currency editable mode",
        style: { color: "red" },
      });
    }
  };
  const MSVsibleHandler = (invoice: any) => {
    setmarkAsSendVisibleHandler(!markAsSendVsibleHandler);
    selectedInvoice.current = invoice;
  };
  const editInvoiceHandler = (index: any) => {
    setVisible1(!visible1);
    let tempArr: any = invoiceVisibility;
    tempArr[index] = true;
    setInvoiceVisibility(tempArr);
  };
  const handleDelete = (id: any) => {
    setRowId(id);
    setShow(true);
  };
  let perc = 0;
  value.forEach(
    (res: { plannedInvoice: any; sentInvoice: any; saleValue: any }) => {
      perc =
        perc +
        checkNull((res.sentInvoice + res.plannedInvoice) / res.saleValue) * 100;
    }
  );
  let unaccounted = 0;
  value.forEach(
    (res: { plannedInvoice: any; sentInvoice: any; saleValue: any }) => {
      unaccounted =
        currencySymb?.currency_code != "INR"
          ? convertNumber(res?.saleValue, 2)
          : checkBase(currencySymb?.currency_code, res?.saleValue);
    }
  );

  return (
    <>
      <Row>
        <Col span={6}>
          <Row>
            <Col>
              <strong>
                <p className="totalClaimed">
                  {invoiced.textForTotalInvoiceable}{" "}
                  {perc ? perc.toFixed(2) : "0"}%
                </p>
                <p>
                  {isEditable
                    ? `${
                        props.currencySymbol[props.poDetails.currency_id]
                      } ${unaccounted}`
                    : checkAndConvertCurrency(
                        unaccounted,
                        props.currencySymbol[props.poDetails.currency_id]
                      )}
                </p>
              </strong>
            </Col>
          </Row>
          <Row>
            <Col span={18}>
              <Progress
                className="RevenueProgress"
                percent={perc}
                showInfo={false}
              />
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Col>
              <label>{invoiced.labelForInvoiceSent}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong className="sentInvoice">
                {isEditable
                  ? `${
                      props.currencySymbol[props.poDetails.currency_id]
                    } ${sentInvoice}`
                  : checkAndConvertCurrency(
                      sentInvoice,
                      props.currencySymbol[props.poDetails.currency_id]
                    )}
              </strong>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Col>
              <label>{invoiced.labelForPlannedInvoice}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>
                {isEditable
                  ? `${
                      props.currencySymbol[props.poDetails.currency_id]
                    } ${plannedInvoice}`
                  : checkAndConvertCurrency(
                      plannedInvoice,
                      props.currencySymbol[props.poDetails.currency_id]
                    )}
              </strong>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Col>
              <label>{invoiced.labelForCreditNote}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>
                {isEditable
                  ? `${
                      props.currencySymbol[props.poDetails.currency_id]
                    } ${netSentAmount}`
                  : checkAndConvertCurrency(
                      netSentAmount,
                      props.currencySymbol[props.poDetails.currency_id]
                    )}
              </strong>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {" "}
        <Col
          span={24}
          className="AddIVButton"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {!visible && !visible1 && !props.archiveStatus && addEditAccess && (
            <Button
              type="primary"
              onClick={visibleHandler}
              className="primary-btn add-po"
              name="AddInvoice"
            >
              {invoiced.buttonForAddInvoice}
            </Button>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          {visible && (
            <AddInvoice
              cancelHandler={visibleHandler}
              newMonthId={props.newMonthId}
              Poid={props.POid}
              mastercurrencies={props.mastercurrencies}
              poDetails={props.poDetails}
              currentInvoiceAmt={currentInvoiceAmt.current}
              currencyName={props.currencyName}
              currencySymbol={props.currencySymbol}
              getData={getData}
              totalAmount={
                isEditable
                  ? `${
                      props.currencySymbol[props.poDetails.currency_id]
                    } ${unaccounted}`
                  : checkAndConvertCurrency(
                      unaccounted,
                      props.currencySymbol[props.poDetails.currency_id]
                    )
              }
              unaccounted={unaccounted}
              sentInvoice={sentInvoice}
              plannedInvoice={plannedInvoice}
            />
          )}
        </Col>
      </Row>
      <div>
        {loading ? (
          <Loader />
        ) : !invoice.length ? (
          <div className="no-invoice-div">
            <FileExclamationOutlined
              style={{ fontSize: "100px", color: "#00000073" }}
            />
            <Text type="secondary" style={{ fontSize: "1.2rem" }}>
              No Invoices
            </Text>
          </div>
        ) : (
          <div className="invoice-list-div">
            {invoice.map((v: any, index: any) => {
              return (
                <>
                  {invoiceVisibility[index] && (
                    <>
                      <div key={index} className="pb-20">
                        <Row
                          gutter={16}
                          style={{ marginInlineStart: "0.2rem" }}
                        >
                          <Col md={12} xs={12}>
                            <Text style={{ color: "#5C67E5" }}>{v.title}</Text>
                          </Col>
                          {!visible && !visible1 && v.status && (
                            <Col
                              md={12}
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              {addEditAccess && isEditable && (
                                <CheckCircleOutlined
                                  className="icon-class"
                                  title="Mark as sent"
                                  onClick={() => MSVsibleHandler(v)}
                                />
                              )}

                              {addEditAccess && isEditable && (
                                <EditOutlined
                                  className="icon-class"
                                  title="Edit invoice"
                                  onClick={() => {
                                    setVisible1(!visible1);
                                    let tempArr: any = invoiceVisibility;
                                    tempArr[index] = false;
                                    setInvoiceVisibility(tempArr);
                                  }}
                                />
                              )}

                              {addEditAccess && isEditable && (
                                <DeleteOutlined
                                  className="delete-icon-class"
                                  title="Delete invoice"
                                  onClick={() => handleDelete(v.id)}
                                />
                              )}
                            </Col>
                          )}
                          {!v.status && (
                            <Col
                              md={12}
                              xs={12}
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <CheckCircleFilled
                                className="filled-icon-class"
                                title="Mark as sent"
                              />
                            </Col>
                          )}
                        </Row>
                        {markAsSendVsibleHandler && (
                          <Row>
                            <Col>
                              <MarkAsSend
                                visible={markAsSendVsibleHandler}
                                onClose={MSVsibleHandler}
                                onCancelButton={MSVsibleHandler}
                                mastercurrencies={props.mastercurrencies}
                                Poid={props.POid}
                                invoice={selectedInvoice.current}
                                currencyName={props.currencyName}
                                currencySymbol={props.currencySymbol}
                                isLast={lastIn}
                                poDetails={props.poDetails}
                                getData={getData}
                              />
                            </Col>
                          </Row>
                        )}
                        <Row
                          gutter={16}
                          style={{ marginInlineStart: "0.2rem" }}
                        >
                          <Col md={8} xs={24} style={{ display: "grid" }}>
                            <label className="mt-0">
                              {invoiced.labelForInvoiceAmt}
                            </label>
                            <Text type="secondary">
                              {isEditable ? (
                                <>
                                  {
                                    props.currencySymbol[
                                      props.poDetails.currency_id
                                    ]
                                  }

                                  {props.currencySymbol[
                                    props.poDetails.currency_id
                                  ] === "₹"
                                    ? Intl.NumberFormat("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }).format(v.invoice_amt)
                                    : Intl.NumberFormat("en-US", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }).format(v.invoice_amt)}
                                  <br />
                                  {`${
                                    props.currencySymbol[
                                      props.poDetails.currency_id
                                    ]
                                  }${Number(v.invoice_amt).toFixed(2)}` !==
                                    convertCurrency(
                                      v.invoice_amt_base,
                                      currencyRate
                                    ) && (
                                    <Text type="secondary">
                                      {invoiced.textForEquivalentValue}{" "}
                                      {convertCurrency(
                                        v.invoice_amt_base,
                                        currencyRate
                                      )}
                                    </Text>
                                  )}
                                </>
                              ) : (
                                <>
                                  {convertCurrency(
                                    v.invoice_amt_base,
                                    currencyRate
                                  )}
                                </>
                              )}
                            </Text>
                          </Col>
                          <Col md={8} xs={24} style={{ display: "grid" }}>
                            <label className="mt-0">
                              {invoiced.labelForExpectedDate}
                            </label>
                            <Text type="secondary">
                              {moment(v.expected_date).format(dateFormat)}
                            </Text>
                          </Col>

                          <Col md={8} xs={24} style={{ display: "grid" }}>
                            <label className="mt-0">
                              {invoiced.labelForDesc}
                            </label>
                            <Text type="secondary">{v.description}</Text>
                          </Col>
                        </Row>
                        {!v.status && (
                          <RenderMarkAsSent
                            invoiceId={v.id}
                            currencyName={props.currencyName}
                            currencySymbol={props.currencySymbol}
                            poDetails={props.poDetails}
                            Poid={props.POid}
                            finalInvoice={v.FinalInvoice}
                          />
                        )}
                      </div>
                      <hr />
                    </>
                  )}
                  {!invoiceVisibility[index] && (
                    <EditInvoice
                      invoiceData={v}
                      Poid={props.POid}
                      cancelHandler={editInvoiceHandler}
                      newMonthId={props.newMonthId}
                      invoiceInstanceIndex={index}
                      mastercurrencies={props.mastercurrencies}
                      poDetails={props.poDetails}
                      currentInvoiceAmt={currentInvoiceAmt.current}
                      currencyName={props.currencyName}
                      getData={getData}
                      unaccounted={unaccounted}
                      sentInvoice={sentInvoice}
                      plannedInvoice={plannedInvoice}
                      totalAmount={
                        isEditable
                          ? `${
                              props.currencySymbol[props.poDetails.currency_id]
                            } ${unaccounted}`
                          : checkAndConvertCurrency(
                              unaccounted,
                              props.currencySymbol[props.poDetails.currency_id]
                            )
                      }
                    />
                  )}
                </>
              );
            })}
          </div>
        )}
      </div>
      <AddModal
        onClose={() => setShow(false)}
        show={show}
        rowData={rowId}
        getData={getData}
      ></AddModal>
    </>
  );
};
export default Invoice;
