import { useRef, useState, useEffect } from "react";
import {
  Drawer,
  Row,
  Col,
  DatePicker,
  Input,
  Typography,
  notification,
  Modal,
  Upload,
} from "antd";
import { Formik } from "formik";
import ButtonReuse from "../../../components/Buttons/Button";
import { useParams } from "react-router-dom";
import moment from "moment";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import * as yup from "yup";
import { PlusOutlined } from "@ant-design/icons";
import {
  getCurrencyRates,
  getRates,
} from "../../../stores/actions/getCurrencyAction";
import { useDispatch, useSelector } from "react-redux";
import { markAsSend } from "../../../utils/enums";

var FormData = require("form-data");
var data = new FormData();

const MarkAsSend = (props: {
  visible?: any;
  onClose?: any;
  onCancelButton?: any;
  mastercurrencies?: any;
  Poid?: any;
  invoice?: any;
  currencyName?: any;
  currencySymbol?:any;
  isLast?: any;
  poDetails?: any;
  getData?: any;
}) => {
  const project_id: any = useParams();
  const { Text } = Typography;
  const dateFormat = "YYYY-MM-DD";
  const finalInvoiceAmt = useRef(props.invoice.invoice_amt);
  const [valueToSend, setValueToSend] = useState<any>({});
  const [CurrencyRate, setCurrencyRate] = useState();
  const [isModalVisibleDelete, setisModalVisibleDelete] = useState(false);
  const [isMarkAsSentClicked, setIsMarkAsSentClicked] = useState(false);
  const isLoading = useRef(false);
  const token = getToken();
  const validationSchema = yup.object({
    invoice_number: yup.string().required("Please enter the invoice number"),
    final_amt: yup
      .number()
      .required("Please select currency & enter the final amount"),
    recorded_date: yup
      .mixed()
      .required("Please enter the recoreded date")
      .nullable(false),
  });
  const onCancelHandler = () => {
    props.onCancelButton(props.visible);
  };
  const handleCancelDeleteModal = () => {
    setisModalVisibleDelete(false);
  };
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const [previewTitle, setPreviewTitle] = useState();
  const [files, setFiles] = useState<any>([]);

  const handleCancel = () => {
    setPreviewVisible(false);
  };
  useEffect(() => {
    if (isMarkAsSentClicked && Object.keys(valueToSend).length) {
      changePoStatus();
    }
  }, [isMarkAsSentClicked, valueToSend]);

  const handlePreview = async (file: any) => {
    // if (!file.url && !file.preview) {
    //   file.preview = await getBase64(file.originFileObj);
    // }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleAttachmentChange = ({ fileList }: any) => {
    setFiles(fileList);
  };

  const currencySymb = useSelector((store: any) => store?.baseCurrency?.baseCurrency);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{markAsSend.buttonForUpload}</div>
    </div>
  );
  console.warn(props.invoice);
  var initialValues = {
    currency_id: props.poDetails.currency_id,
    currency_rate: props.poDetails.currency_rate,
    final_amt: props.invoice.invoice_amt,
    final_amt_base: props.invoice.invoice_amt_base,
    invoice_number: "",
    recorded_date: "",
    po_id: "",
    project_id: "",
    files: [],
  };
  const changePoStatus = () => {
    data.append("currency_id", valueToSend.currency_id);
    data.append("currency_rate", valueToSend.currency_rate);
    data.append("final_amt", valueToSend.final_amt);
    data.append("final_amt_base", valueToSend.final_amt_base);
    data.append("invoice_number", valueToSend.invoice_number);
    data.append("recorded_date", valueToSend.recorded_date);
    data.append("po_id", valueToSend.po_id);
    data.append("project_id", valueToSend.project_id);
    data.append("is_last", true);
    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i].originFileObj);
    }
    AxiosConfig.post(`finalinvoice/${props.invoice.id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      if (response.status == 200) {
        const notify = () => {
          notification.open({
            message: response.data.status.message,
          });
        };
        {
          notify();
        }
        props.getData()
        props.onCancelButton()
      }
    });
  };
  const dontChangePoStatus = () => {
    setisModalVisibleDelete(false);
  };
  const dispatch: any = useDispatch();
  const currencyData = useSelector((store: any) => store?.currency?.data);
  const ratesData = useSelector((store: any) => store?.rate);

  useEffect(() => {
    if (currencyData.id) {
      dispatch(getRates(currencyData.id));
    }
  }, [currencyData]);
  useEffect(() => {
    if (ratesData.data && ratesData.data.mastercurrencies) {
      const temp: any = ratesData.data.mastercurrencies.filter(
        (currency: any) => {
          return (
            currency.currencyrates.currency_id === props.poDetails.currency_id
          );
        }
      );
      const retvalue: any =
        temp[0]?.currencyrates.rate ?? props.poDetails.currency_rate;

      setCurrencyRate(retvalue);
    }
  }, [ratesData]);
  async function rates(month: any, year: any) {
    if (month && year) {
      dispatch(getCurrencyRates(month, year));
    }
  }

  return (
    <>
      <Drawer
        title="Mark As Sent"
        width={700}
        onClose={props.onClose}
        visible={props.visible}
        bodyStyle={{ paddingBottom: 80 }}
        className="add_talent"
        closable={false}
        maskClosable={false}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            if (props.isLast == true) {
              setIsMarkAsSentClicked(true);
              setValueToSend({
                ...values,
                final_amt: Number(values.final_amt),
                invoice_number: values.invoice_number,
                final_amt_base: Number(values.final_amt) * Number(CurrencyRate),
                recorded_date: moment(values.recorded_date).format(dateFormat),
                po_id: props.Poid,
                project_id: parseInt(project_id.id),
                files: [],
              });
            } else {
              data.append("currency_id", initialValues.currency_id);
              data.append("currency_rate", initialValues.currency_rate);
              data.append("final_amt", Number(values.final_amt));
              data.append(
                "final_amt_base",
                Number(values.final_amt) * props.poDetails.currency_rate
              );
              data.append("invoice_number", values.invoice_number);
              data.append(
                "recorded_date",
                moment(values.recorded_date).format(dateFormat)
              );
              data.append("po_id", props.Poid);
              data.append("project_id", parseInt(project_id.id));
              data.append("is_last", true);
              for (let i = 0; i < files.length; i++) {
                data.append("files", files[i].originFileObj);
              }
              if (!isLoading.current) {
                isLoading.current = true;
                try {
                  const response = await AxiosConfig.post(
                    `finalinvoice/${props.invoice.id}`,
                    data,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  );
                  if (response.status === 200) {
                    const notify = () => {
                      notification.open({
                        message: response.data.status.message,
                      });
                    };
                    notify();
                    props.getData();
                    props.onCancelButton();
                  }
                } catch (error) {
                  console.error("Error submitting form:", error);
                } finally {
                  isLoading.current = false;
                }
              }
            }
            resetForm();
            data = new FormData();
          }}
        >
          {({
            handleSubmit,
            handleChange,
            errors,
            values,
            setFieldValue,
            resetForm,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="main-div">
                <Row gutter={16}>
                  <Col span={24}>
                    <label> {markAsSend.labelForInvoiceNumber}</label>
                    <Input
                      onChange={handleChange}
                      placeholder="Enter the invoice number"
                      name="invoice_number"
                      className="input--field"
                    />
                    <p className="display_error">{errors.invoice_number}</p>
                  </Col>

                  <Col span={24}>
                    <label> {markAsSend.labelForFinalAmount}</label>
                    <Input
                      className="mb--10 input--field"
                      onChange={(event: any) => {
                        finalInvoiceAmt.current = event.target.value;
                        return handleChange(event);
                      }}
                      value={values.final_amt}
                      addonBefore={
                        props.currencySymbol[props.poDetails.currency_id]
                      }
                      name="final_amt"
                      placeholder="Enter the Final amount"
                    />
                    <Text type="secondary" style={{ display: "grid" }}>
                      {markAsSend.labelForConversionRate}
                      {currencySymb?.currency_symbol}
                      {values.recorded_date
                        ? CurrencyRate
                        : props.poDetails.currency_rate}
                      | {currencySymb?.currency_code}:
                      {currencySymb?.currency_symbol}
                      {(values.recorded_date
                        ? CurrencyRate
                        : props.poDetails.currency_rate) *
                        finalInvoiceAmt.current}
                    </Text>
                    <p className="display_error">{errors.final_amt_base}</p>
                  </Col>
                  <Col span={24}>
                    <label>{markAsSend.labelForRecordedDate}</label>
                    <DatePicker
                      defaultValue={undefined}
                      format={dateFormat}
                      onChange={(val) => {
                        const date = moment(val, "YYYY/MM/DD");
                        const month = date.format("MMMM");
                        const year = date.format("YYYY");
                        rates(month, year);
                        setFieldValue("recorded_date", val);
                      }}
                      placeholder="Select the recorded date"
                      name="recorded_date"
                      className="input--field"
                    />
                    <p className="display_error">{errors.recorded_date}</p>
                  </Col>
                  <Col span={24}>
                    <label>{markAsSend.labelForAttachments}</label>
                    <Upload
                      // action="/projects/attachments/upload"
                      // fileList={files}
                      listType="picture-card"
                      fileList={files}
                      onPreview={handlePreview}
                      onChange={handleAttachmentChange}
                      beforeUpload={() => false}
                    >
                      {uploadButton}
                      {/* {files.length >= 8 ? null : uploadButton} */}
                    </Upload>
                    <Modal
                      visible={previewVisible}
                      title={previewTitle}
                      footer={null}
                      onCancel={handleCancel}
                    >
                      <img
                        alt="example"
                        style={{ width: "100%" }}
                        src={previewImage}
                      />
                    </Modal>
                  </Col>
                </Row>
                <Modal
                  title="Change PO status"
                  visible={isModalVisibleDelete}
                  //onOk={handleDeleteModal}
                  onCancel={handleCancelDeleteModal}
                  cancelText="Cancel"
                  footer={false}
                  className="deleteModalMain"
                >
                  <p>{markAsSend.warningTextForPoStatus}</p>
                  <Row>
                    <Col span={12}>
                      <ButtonReuse
                        value="Yes"
                        type="submit"
                        className="modal-deleteCancelBtn"
                        onClick={changePoStatus}
                      />
                    </Col>
                    <Col span={12}>
                      <ButtonReuse
                        value="No"
                        type="submit"
                        className="modal-deleteCancelBtn"
                        onClick={dontChangePoStatus}
                      />
                    </Col>
                  </Row>
                </Modal>
                <div
                  className="button-container"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "0",
                  }}
                >
                  <ButtonReuse
                    type="primary"
                    className="primary-btn"
                    htmlType="submit"
                    value="Mark As Sent"
                  ></ButtonReuse>
                  <ButtonReuse
                    type="primary"
                    className="primary-btn cancel--btn"
                    value="Cancel"
                    onClick={() => {
                      resetForm();
                      onCancelHandler();
                    }}
                  ></ButtonReuse>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};
export default MarkAsSend;
