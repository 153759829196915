import { AxiosConfig } from "../../../ApiConfig";
import { Row, Col, DatePicker, Input, notification } from "antd";
import * as yup from "yup";
import { useFormik } from "formik";
import ButtonReuse from "../../../components/Buttons/Button";
import Select from "react-select";
import { getToken } from "../../../auth";
import moment from "moment";
import { bandRecord } from "../../../utils/enums";

require("./RecordData.scss");

const BandRecord = (props: {
  selectedRecord?: any;
  onCancelHandler?: any;
  bandOptions?: any;
}) => {
  const token = getToken();
  const { TextArea } = Input;
  const dateFormat = "YYYY/MM/DD";
  let talentData: any = localStorage.getItem("talentid");
  talentData = JSON.parse(talentData);
  const currentUserId = localStorage.getItem("userId");

  const initialValues = {
    old_value: talentData.Band,
    new_value: "",
    new_band_id: "",
    date_of_change: "",
    change_type_id: props.selectedRecord?.key || "",
    user_id: currentUserId || "",
    talent_id: talentData["Talent_id"] || "",
    notes: "",
  };

  const validationSchema = yup.object({
    notes: yup.string().required("Please enter some notes"),
    date_of_change: yup
      .mixed()
      .required("Please enter the date of change")
      .nullable(false),
    new_value: yup.string().required("Please select the new band"),
  });

  const notify = (message: string, description?: string, color?: string) => {
    notification.open({
      message,
      description,
      style: { color },
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      values.date_of_change = moment(values.date_of_change).format(dateFormat);

      AxiosConfig.post("talents/changes/bands", values, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          talentData = { ...talentData, Band: values.new_value };
          localStorage.setItem("talentid", JSON.stringify(talentData));
          if (response.status === 200) {
            notify(response.data.status.message);
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }
        })
        .catch(function (error) {
          for (const key in values) {
            delete values[key];
          }
          notify("", error.response.data.status.message, "red");
        });
    },
  });

  return (
    <div className="record-data-form">
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col span={24}>
            <label>{bandRecord.labelForExistingBand}</label>
            <Input className="input--field" value={talentData.Band} disabled />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label>{bandRecord.labelForNewBand}</label>
            <Select
              options={props.bandOptions?.filter(
                (option) => option.value !== talentData.Band
              )}
              name="new_band_id"
              placeholder="Select the Band"
              className="select--category"
              value={{
                key: formik.values.new_band_id,
                label: formik.values.new_value,
              }}
              onChange={(value: any) => {
                formik.setFieldValue("new_band_id", value.key);
                formik.setFieldValue("new_value", value.label);
              }}
            />
            <p className="display_error">{formik.errors.new_value}</p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label style={{ display: "flex" }}>Date</label>
            <DatePicker
              defaultValue={undefined}
              format={dateFormat}
              value={
                formik.values.date_of_change
                  ? moment(formik.values.date_of_change)
                  : null
              }
              onChange={(val) => {
                formik.setFieldValue("date_of_change", val);
              }}
              placeholder="Select the Date"
              name="date_of_change"
              className="input--field"
            />
            <p className="display_error">{formik.errors.date_of_change}</p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <label>{bandRecord.labelForNotes}</label>
            <TextArea
              rows={4}
              placeholder="Enter the Notes"
              className="input--field textarea--field"
              value={formik.values.notes}
              name="notes"
              onChange={formik.handleChange}
            />
            <p className="display_error">{formik.errors.notes}</p>
          </Col>
        </Row>
        <div className="button-container">
          <ButtonReuse
            type="primary"
            className="primary-btn"
            htmlType="submit"
            value="Record"
          ></ButtonReuse>
          <ButtonReuse
            type="primary"
            className="primary-btn cancel--btn"
            value="Cancel"
            onClick={() => {
              formik.resetForm();
              props.onCancelHandler();
            }}
          ></ButtonReuse>
        </div>
      </form>
    </div>
  );
};

export default BandRecord;
