import { AxiosConfig } from "../../../ApiConfig";
import { Row, Col, DatePicker, Input, notification } from "antd";
import * as yup from "yup";
import { useFormik } from "formik";
import ButtonReuse from "../../../components/Buttons/Button";
import { getToken } from "../../../auth";
import moment from "moment";
import { resignationRecord } from "../../../utils/enums";
require("./RecordData.scss");

const ResignationRecord = (props: {
  selectedRecord?: any;
  onCancelHandler?: any;
  selectedData?: any;
}) => {
  const token = getToken();
  const { TextArea } = Input;
  const dateFormat = "YYYY/MM/DD";
  let talentData: any = localStorage.getItem("talentid");
  talentData = JSON.parse(talentData);
  const currentUserId = localStorage.getItem("userId");

  const validationSchema: any = yup.object({
    notes: yup.string().required("Please enter some notes"),
    date_of_exit: yup
      .mixed()
      .required("Please enter the date of exit")
      .nullable(false),
  });

  const formik: any = useFormik({
    initialValues: {
      date_of_exit: "",
      change_type_id: "",
      user_id: "",
      talent_id: "",
      notes: "",
      record_id: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      values = {
        ...values,
        date_of_exit: moment(values.date_of_exit).format(dateFormat),
        change_type_id: props.selectedRecord["key"],
        user_id: currentUserId,
        talent_id: talentData["Talent_id"],
        record_id: props?.selectedData?.recordId,
      };
      let record_id = props?.selectedData?.recordId
        ? props.selectedData.recordId
        : null;

      AxiosConfig.post(`talents/changes/resignation/${record_id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          if (response.status == 200) {
            const notify = () => {
              notification.open({
                message: response.data.status.message,
              });
            };
            {
              notify();
            }
            setTimeout(function () {
              window.location.reload();
            }, 1000);
          }
        })
        .catch(function () {
          const notify = () => {
            notification.open({
              message: "",
              description: `Invalid Input. Please enter a valid date`,
              style: { color: "red" },
            });
          };
          {
            for (const key in values) {
              delete values[key];
            }
            notify();
          }
        });
    },
  });

  /*function disabledDate(current) {
    return current && current < moment().startOf('day'); 
  }*/

  return (
    <>
      <div className="record-data-form">
        <form onSubmit={formik.handleSubmit}>
          <Row>
            <Col span={24}>
              <label>{resignationRecord.labelForLastWorkingDay}</label>
              <DatePicker
                defaultValue={undefined}
                format={dateFormat}
                value={formik.values.date_of_exit}
                placeholder="Enter the Date of Exit"
                name="date_of_exit"
                className="input--field"
                //disabledDate={disabledDate}
                onChange={(val) => {
                  formik.setFieldValue("date_of_exit", val);
                }}
              />
              <p className="display_error">{formik.errors.date_of_exit}</p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <label>{resignationRecord.labelForNotes}</label>
              <TextArea
                rows={4}
                placeholder="Enter the Notes"
                className="input--field textarea--field"
                value={formik.values.notes}
                name="notes"
                onChange={formik.handleChange}
              />
              <p className="display_error">{formik.errors.notes}</p>
            </Col>
          </Row>
          <div className="button-container">
            <ButtonReuse
              type="primary"
              className="primary-btn"
              htmlType="submit"
              value="Record"
            ></ButtonReuse>
            <ButtonReuse
              type="primary"
              className="primary-btn cancel--btn"
              value="Cancel"
              onClick={() => {
                formik.resetForm();
                props.onCancelHandler();
              }}
            ></ButtonReuse>
          </div>
        </form>
      </div>
    </>
  );
};
export default ResignationRecord;
