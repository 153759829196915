import { useState, useEffect } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { Row, Col, Input, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { InputField } from "../../../components";
import * as yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import { getToken } from "../../../auth";
require("./RecordData.scss");
import ButtonReuse from "../../../components/Buttons/Button";
import moment from "moment";
import { Axios } from "../../../utils/axios";
import { unavailableRecords } from "../../../utils/enums";
import {
  allowOnlyNumbers,
  convertUnavailableHours,
  errorNotification,
  successNotification,
} from "../../../utils/util";

const UnavailableRecord = (props: {
  onCancelHandler?: any;
  change_type?: any;
  selectedData?: any;
  isEdit?: boolean;
}) => {
  const token = getToken();
  const { TextArea } = Input;
  const currentUserId = localStorage.getItem("userId");
  let talentData: any = localStorage.getItem("talentid");
  talentData = JSON.parse(talentData);

  const [monthsList, setMonthsList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [deptHistory, setDeptHistory] = useState();
  const [skillHistory, setSkillHistory] = useState();
  const [bandHistory, setBandHistory] = useState();
  const [isLoad, setLoad] = useState(false);
  const [monthId, setmonthId] = useState();
  const [availableHours, setAvailableHours] = useState(0);
  const [getApiavailableHours, setApiAvailableHours] = useState(0);

  const validationSchema: any = yup.object({
    notes: yup.string().trim().required("Please enter some notes"),
    monthId: yup.string().required("Please select a month"),
    department_id: yup.string().required("Please select a department"),
    skill_id: yup.string().required("Please select a skill"),
    band_id: yup.string().required("Please select a band"),
    hours: yup.number().min(0).required("Please enter some hours"),
  });

  const [defaultMonth, setDefaultMonth] = useState({});
  const [defaultDepart, setDefaultDepart] = useState({});
  const [defaultSkill, setDefaultSkill] = useState({});
  const [defaultBand, setDefaultBand] = useState({});
  const [initialValues, setInitialValues] = useState<any>({
    talentId: "",
    monthId: props.selectedData ? props.selectedData.monthId : "",
    notes: props.selectedData ? props.selectedData.notes : "",
    hours: props.selectedData ? props.selectedData.new_value : "",
    department_id: props.selectedData ? props.selectedData?.department?.id : "",
    skill_id: props.selectedData ? props.selectedData?.skill?.id : "",
    band_id: props.selectedData ? props.selectedData?.band?.id : "",
    date_of_change: "",
    change_type_id: props.selectedData ? props.selectedData.change_type_id : "",
    user_id: "",
  });

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values: any) => {
      values = {
        ...values,
        talentId: talentData["Talent_id"],
        date_of_change: moment(new Date()).format("YYYY-MM-DD"),
        change_type_id: props.change_type,
        user_id: currentUserId,
      };
      let url = "talents/unavailablehours";
      if (props.isEdit) {
        url = `talents/unavailablehours/${props.selectedData.recordId}`;
      }
      try {
        let response: any = {};
        if (props.isEdit) {
          response = await Axios.put(url, values);
        } else {
          response = await Axios.post(url, values);
        }

        if (response.status == 200) {
          successNotification(response.data.status.message);
          setTimeout(function () {
            window.location.reload();
          }, 200);
        } else {
          console.log("Error:", response.status);
        }
      } catch (error: any) {
        errorNotification(error.response.data.status.message);
      }
    },
  });

  const onChanges = (e) => {
    let val = allowOnlyNumbers(e);
    if (val !== "") {
      val = typeof val == "string" ? parseInt(val) : val;
      const totalPercent = convertUnavailableHours(val, getApiavailableHours);
      formik.setFieldValue("hours", val);
      setAvailableHours(totalPercent);
    } else {
      formik.setFieldValue("hours", 0);
      setAvailableHours(getApiavailableHours);
    }
  };

  useEffect(() => {
    if (props.isEdit && props.selectedData) {
      setInitialValues({
        ...initialValues,
        ["notes"]: props.selectedData.notes,
        ["monthId"]: props.selectedData.monthId,
        ["hours"]: props.selectedData.new_value,
        ["department_id"]: props.selectedData?.department?.id,
        ["skill_id"]: props.selectedData?.skill?.id,
        ["band_id"]: props.selectedData?.band?.id,
        ["date_of_change"]: props.selectedData.date_of_change,
        ["change_type_id"]: props.selectedData.change_type_id,
      });

      formik.setFieldValue("notes", props.selectedData.notes);
      formik.setFieldValue("monthId", props.selectedData.monthId);
      formik.setFieldValue("hours", props.selectedData.new_value);
      formik.setFieldValue("department_id", props.selectedData?.department?.id);
      formik.setFieldValue("skill_id", props.selectedData?.skill?.id);
      formik.setFieldValue("band_id", props.selectedData?.band?.id);
      formik.setFieldValue("change_type_id", props.selectedData.change_type_id);

      setDefaultMonth({
        value: props.selectedData.monthId,
        label: `${props.selectedData.month}, ${props.selectedData.year}`,
      });
      setDefaultDepart({
        value: props.selectedData?.department?.id,
        label: props.selectedData?.department?.name,
      });
      setDefaultSkill({
        value: props.selectedData?.skill?.id,
        label: props.selectedData?.skill?.name,
      });
      setDefaultBand({
        value: props.selectedData?.band?.id,
        label: props.selectedData?.band?.name,
      });
      setLoad(true);
    }
  }, [props.selectedData]);

  useEffect(() => {
    AxiosConfig.get("financialmonths", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const tempMonthList: any = [];
        //const today = new Date();
        // const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        res.data.data.months.forEach((month: any) => {
          //const dateString = `${month.month} 1, ${month.year}`;
          //const listDate = new Date(dateString);
          // if (listDate >= thisMonth) {
          tempMonthList.push({
            value: month.id,
            label: `${month.month}, ${month.year}`,
          });
          // }
        });
        setMonthsList(tempMonthList);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const month = selectedMonth.split(", ")[0];
    const year = parseInt(selectedMonth.split(", ")[1]);
    AxiosConfig.get(
      `talents/hours/${talentData["Talent_id"]}?monthId=${monthId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((res) => {
      setApiAvailableHours(res.data.availableHours);
      setAvailableHours(res.data.availableHours);
    });
    AxiosConfig.post(
      "talents/talenthistory",
      { month, year, talent_id: talentData["Talent_id"] },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        const tempDeptHistory: any = [];
        const tempSkillHistory: any = [];
        const tempBandHistory: any = [];
        res.data.data.talentDeptHistory.forEach((dept: any) =>
          tempDeptHistory.push({
            value: dept.department_id,
            label: dept.name,
          })
        );
        res.data.data.talentSkillHistory.forEach((skill: any) =>
          tempSkillHistory.push({
            value: skill.skill_id,
            label: skill.name,
          })
        );
        res.data.data.talentbandHistory.forEach((band: any) =>
          tempBandHistory.push({
            value: band.band_id,
            label: band.name,
          })
        );
        setDeptHistory(tempDeptHistory);
        setSkillHistory(tempSkillHistory);
        setBandHistory(tempBandHistory);
        setLoad(true);
      })
      .catch((err) => console.log(err));
  }, [selectedMonth]);

  return (
    <div className="record-data-form">
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col span={24}>
            <label>{unavailableRecords.labelForMonth}</label>
            {props.isEdit ? (
              <>
                <Select
                  isDisabled={props.isEdit ? true : false}
                  options={monthsList}
                  value={defaultMonth}
                  name="month_id"
                  placeholder="Select the Month"
                  className="select--field"
                  onChange={(value: any) => {
                    setmonthId(value.value);
                    setSelectedMonth(value.label);
                    return formik.setFieldValue("monthId", value.value);
                  }}
                />
              </>
            ) : (
              <>
                <Select
                  isDisabled={props.isEdit ? true : false}
                  options={monthsList}
                  name="month_id"
                  placeholder="Select the Month"
                  className="select--field"
                  onChange={(value: any) => {
                    setmonthId(value.value);
                    setSelectedMonth(value.label);
                    return formik.setFieldValue("monthId", value.value);
                  }}
                />
              </>
            )}
            <p className="display_error">{formik.errors.monthId}</p>
          </Col>
        </Row>
        {isLoad && (
          <>
            <Row>
              <Col span={24}>
                <label>{unavailableRecords.labelForDepartments} </label>
                {props.isEdit ? (
                  <>
                    <Select
                      isDisabled={props.isEdit ? true : false}
                      options={deptHistory}
                      value={defaultDepart}
                      name="dept_id"
                      placeholder="Select the Department"
                      className="select--field"
                      onChange={(value: any) =>
                        formik.setFieldValue("department_id", value.value)
                      }
                    />
                  </>
                ) : (
                  <>
                    <Select
                      isDisabled={props.isEdit ? true : false}
                      options={deptHistory}
                      name="dept_id"
                      placeholder="Select the Department"
                      className="select--field"
                      onChange={(value: any) =>
                        formik.setFieldValue("department_id", value.value)
                      }
                    />
                  </>
                )}
                <p className="display_error">{formik.errors.department_id}</p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <label>{unavailableRecords.labelForSkill}</label>
                {props.isEdit ? (
                  <>
                    <Select
                      isDisabled={props.isEdit ? true : false}
                      options={skillHistory}
                      value={defaultSkill}
                      name="skill_id"
                      placeholder="Select the Skill"
                      className="select--field"
                      onChange={(value: any) =>
                        formik.setFieldValue("skill_id", value.value)
                      }
                    />
                  </>
                ) : (
                  <>
                    <Select
                      isDisabled={props.isEdit ? true : false}
                      options={skillHistory}
                      name="skill_id"
                      placeholder="Select the Skill"
                      className="select--field"
                      onChange={(value: any) =>
                        formik.setFieldValue("skill_id", value.value)
                      }
                    />
                  </>
                )}
                <p className="display_error">{formik.errors.skill_id}</p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <label>{unavailableRecords.labelForBand}</label>
                {props.isEdit ? (
                  <>
                    <Select
                      isDisabled={props.isEdit ? true : false}
                      options={bandHistory}
                      value={defaultBand}
                      name="band_id"
                      placeholder="Select the Band"
                      className="select--field"
                      onChange={(value: any) =>
                        formik.setFieldValue("band_id", value.value)
                      }
                    />
                  </>
                ) : (
                  <>
                    <Select
                      isDisabled={props.isEdit ? true : false}
                      options={bandHistory}
                      name="band_id"
                      placeholder="Select the Band"
                      className="select--field"
                      onChange={(value: any) =>
                        formik.setFieldValue("band_id", value.value)
                      }
                    />
                  </>
                )}
                <p className="display_error">{formik.errors.band_id}</p>
              </Col>
            </Row>
            <Row>
              <Col span={20}>
                {props.isEdit ? (
                  <label>
                    {unavailableRecords.labelForHours}
                    <Tooltip title="Enter Unavailable hours only">
                      <InfoCircleOutlined
                        style={{ marginLeft: "5px", color: "blue" }}
                      />
                    </Tooltip>
                  </label>
                ) : (
                  <label>
                    {unavailableRecords.labelForAvailableHours}
                    <Tooltip title="Enter Unavailable hours only">
                      <InfoCircleOutlined
                        style={{ marginLeft: "5px", color: "blue" }}
                      />
                    </Tooltip>
                  </label>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <InputField
                    placeholder="Enter the Hours"
                    name="hours"
                    onChange={onChanges}
                    type="number"
                    value={formik.values.hours}
                    className="input--field"
                  ></InputField>
                  {!props.isEdit ? (
                    <InputField
                      className="AvailableHours-field"
                      value={availableHours}
                    ></InputField>
                  ) : (
                    ""
                  )}
                </div>
                <p className="display_error">{formik.errors.hours}</p>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <label>{unavailableRecords.labelForNotes}</label>
                <TextArea
                  rows={4}
                  placeholder="Enter the Notes"
                  className="input--field textarea--field"
                  value={formik.values.notes}
                  name="notes"
                  onChange={formik.handleChange}
                />
                <p className="display_error">{formik.errors.notes}</p>
              </Col>
            </Row>
          </>
        )}
        <div className="button-container">
          <ButtonReuse
            type="primary"
            className="primary-btn"
            htmlType="submit"
            value="Record"
          ></ButtonReuse>
          <ButtonReuse
            type="primary"
            className="primary-btn cancel--btn"
            value="Cancel"
            onClick={() => {
              formik.resetForm();
              props.onCancelHandler();
            }}
          ></ButtonReuse>
        </div>
      </form>
    </div>
  );
};
export default UnavailableRecord;
